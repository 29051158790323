<template>
  <Container
    class="chart"
    :preloader="preloader"
  >
    <template #content>
      <div class="chart__content">
        <div class="chart__content__name">
          Оптовый оборот
        </div>

        <h3>
          ${{ $format.wallet(value.amount) }}
        </h3>

        <div class="chart__content__all">
          За всю кампанию
        </div>
      </div>

      <LineChart
        v-if="!preloader"
        class="chart__graph"
        :chart-data="datacollection"
        :options="options"
      />
    </template>
  </Container>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import { LineChart } from 'vue-chart-3'

Chart.register(...registerables)

export default {
  components: {
    Container,
    LineChart
  },
  data () {
    return {
      preloader: true,
      value: {},
      datacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'rgba(15, 191, 230, 0.5)',
            borderWidth: 0.5,
            fill: true,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 75)

              gradient.addColorStop(0, 'rgba(15, 191, 230, 0.3)')
              gradient.addColorStop(1, 'rgba(15, 191, 230, 0)')

              return gradient
            },
            borderJoinStyle: 'round',
            tension: 0
          }
        ]
      },
      options: {
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x'
        },
        plugins: {
          responsive: true,
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        elements: {
          point: {
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent'
          }
        },
        layout: {
          padding: 0
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true
          },
          x: {
            display: false,
            afterFit: scale => {
              scale.paddingLeft -= 10
              scale.paddingRight -= 10
            }
          }
        }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.value = (await this.$axios.get(
          `private/advertising-campaigns/purchases/${this.$route.params.id}`)).data.data
        this._parseData(this.value.graph)
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    _parseData (value) {
      var labels = []
      var data = []

      for (const i in value) {
        labels.push(i)
        data.push(value[i])
      }

      if (value.length === 0) {
        labels.push(0, 1)
        data.push(500, 500)
      }

      this.datacollection.labels = labels
      this.datacollection.datasets[0].data = data
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  min-height: 160px;

  &__content {
    padding: 20px;

    &__name {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 100%;
      color: #202539;
      font-family: Medium;
    }

    h3 {
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    &__all {
      margin-top: 5px;
      font-size: 12px;
      line-height: 100%;
      color: #787C8D;
      font-family: Medium;
    }
  }

  &__graph {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    width: 100%;
  }
}
</style>
