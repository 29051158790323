<template>
  <UiPage
    v-if="!preloader"
    :title="value.name"
    :titleTemplate="value.name"
    class="content-padding-mobile"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="chart-grid">
        <Transitions />

        <Turnover />

        <Link
          :slug="value.slug"
        />
      </div>

      <List />
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import Transitions from '@/modules/profile/advertising-campaigns/chart/TransitionsUnique.vue'
import Turnover from '@/modules/profile/advertising-campaigns/chart/WholesaleTurnover.vue'
import Link from '@/modules/profile/advertising-campaigns/chart/UniqLink.vue'
import List from '@/modules/profile/advertising-campaigns/SelectList.vue'

export default {
  components: {
    UiPage,
    BackButton,
    Transitions,
    Turnover,
    Link,
    List
  },
  data () {
    return {
      preloader: true,
      value: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.value = (await this.$axios.get(
          `private/advertising-campaigns/${this.$route.params.id}`)).data.data
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-grid {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
}
</style>
