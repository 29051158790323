<template>
  <Container
    class="chart"
    :preloader="preloader"
  >
    <template #content>
      <div class="chart__name">
        Уникальная ссылка кампании
      </div>

      <div class="chart__bottom">
        onlix.io/pro/{{ slug }}

        <Copy
          class="chart__bottom__button simple h24"
          :value="`https://onlix.io/pro/${slug}`"
        />
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import Copy from '@/ui/buttons/Copy.vue'

export default {
  components: {
    Container,
    Copy
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      preloader: false
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  min-height: 160px;
  padding: 20px 5px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__name {
    font-size: 16px;
    line-height: 100%;
    color: #202539;
    font-family: Medium;
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 14px;
    line-height: 100%;
    color: #2F57E9;

    &__button {
      &::v-deep {
        &:hover {
          .copy-button__icon {
            background: rgba(87, 127, 255, 1);

            svg {
              &.copy {
                path {
                  fill: none !important;
                }
              }

              path {
                fill: #fff;
              }
            }
          }
        }

        .copy-button__icon {
          width: 26px !important;
          background: rgba(234, 238, 253, 1);
        }
      }
    }
  }
}
</style>
