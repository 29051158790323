<template>
  <Container
    class="select-list"
    :preloader="preloader"
  >
    <template #content>
      <table
        v-if="$store.state.window.width >= 1400"
      >
        <thead>
          <tr>
            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.date') }}
            </th>

            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.transitions-unique') }}
            </th>

            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.registrations') }}
            </th>

            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.purchases') }}
            </th>

            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.turnover') }}
            </th>

            <th>
              {{ $t('views.default.profile.advertising-campaigns.select-list.ref-bonus') }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in value?.data"
            :key="item.id"
          >
            <td style="max-width: 215px">
              {{ $format.date(item.created_at) }}
            </td>

            <td>
              {{ item.transitions }}
            </td>

            <td>
              {{ item.registrations }}
            </td>

            <td>
              {{ item.purchases }}

              <span>
                /
              </span>

              {{ $format.wallet(item.purchases_products) }}
            </td>

            <td>
              ${{ item.amount }}

              <span>
                /
              </span>

              ${{ $format.wallet(item.amount_products) }}
            </td>

            <td>
              ${{ $format.wallet(item.referral) }}

              <span>
                /
              </span>

              ${{ $format.wallet(item.referral_products) }}
            </td>
          </tr>
        </tbody>
      </table>

      <template
        v-else
      >
        <div
          v-for="item in value?.data"
          :key="item.id"
          class="select-list__item"
        >
          <div class="select-list__item__start">
            <div class="select-list__item__start__name">
              {{ $format.date(item.created_at) }}
            </div>
          </div>

          <div class="select-list__item__center">
            <div class="select-list__item__center__row">
              <div class="select-list__item__center__row__name">
                {{ $t('views.default.profile.advertising-campaigns.select-list.transitions-unique') }}
              </div>

              <div class="select-list__item__center__row__content">
                {{ item.transitions }}
              </div>
            </div>

            <div class="select-list__item__center__row">
              <div class="select-list__item__center__row__name">
                {{ $t('views.default.profile.advertising-campaigns.select-list.registrations') }}
              </div>

              <div class="select-list__item__center__row__content">
                {{ item.registrations }}
              </div>
            </div>

            <div class="select-list__item__center__row">
              <div class="select-list__item__center__row__name">
                {{ $t('views.default.profile.advertising-campaigns.select-list.purchases') }}
              </div>

              <div class="select-list__item__center__row__content">
                {{ item.purchases }}

                <span>
                  /
                </span>

                {{ $format.wallet(item.purchases_products) }}
              </div>
            </div>

            <div class="select-list__item__center__row">
              <div class="select-list__item__center__row__name">
                {{ $t('views.default.profile.advertising-campaigns.select-list.turnover') }}
              </div>

              <div class="select-list__item__center__row__content">
                ${{ $format.wallet(item.amount) }}

                <span>
                  /
                </span>

                ${{ $format.wallet(item.amount_products) }}
              </div>
            </div>

            <div class="select-list__item__center__row">
              <div class="select-list__item__center__row__name">
                {{ $t('views.default.profile.advertising-campaigns.select-list.ref-bonus') }}
              </div>

              <div class="select-list__item__center__row__content">
                ${{ $format.wallet(item.referral) }}

                <span>
                  /
                </span>

                ${{ $format.wallet(item.referral_products) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="_pages > 1"
        class="select-list__navigation"
      >
        <div class="select-list__navigation__content">
          <Pagination
            button
            :pages="_pages"
            :page="_activePage"
            :load-more="value.count > limit"
            :loading="isFetchMore"
            @page="$router.push({ name: 'AdvertisingСampaignsSelect', params: { id: $route.params.id }, query: { page: $event } })"
            @loadMore="fetchMore()"
          />
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    Container,
    Pagination
  },
  data () {
    return {
      preloader: true,
      isFetchMore: false,
      limit: 30,
      value: []
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    _activePage () {
      return Number(this.$route.query.page) || 1
    },
    _pages () {
      return Math.ceil(this.value?.count / this.limit)
    },
    _offset () {
      return ((this.$route.query.page ? this.$route.query.page : 1) * this.limit) - this.limit
    }
  },
  methods: {
    async fetch () {
      try {
        this.value = (await this.$axios.get(
          `private/advertising-campaigns/stats/${this.$route.params.id}?limit=${this.limit}&offset=${this._offset}`)).data
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    async fetchMore () {
      try {
        this.isFetchMore = true

        const value = this.value.data
        const offset = this._offset + (value.length ? value.length : 0)
        const response = (await this.$axios.get(
          `private/advertising-campaigns/stats/${this.$route.params.id}?limit=${this.limit}&offset=${offset}`)).data.data

        for (const item of response) {
          this.value.data.push(item)
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.query.page' () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.select-list {
  padding: 20px 10px;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      tr {
        th {
          padding: 0 10px 20px;
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          text-align: left;
          font-family: Medium;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background: rgba(240, 242, 248, .5);
          }
        }

        td {
          padding: 25px 10px;
          font-size: 14px;
          line-height: 100%;
          color: #202539;

          &:first-child {
            padding: 25px 10px 25px 20px;
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            padding: 25px 20px 25px 10px;
            border-radius: 0 10px 10px 0;
          }

          span {
            color: #9CA3B8;
            font-size: inherit;
            line-height: inherit;
          }

          .copy-link {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: inherit;
            line-height: inherit;
            color: inherit;

            &__button {
              &::v-deep {
                &:hover {
                  .copy-button__icon {
                    background: rgba(87, 127, 255, 1);

                    svg {
                      &.copy {
                        path {
                          fill: none !important;
                        }
                      }

                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                .copy-button__icon {
                  background: rgba(234, 238, 253, 1);
                }
              }
            }
          }

          .details {
            font-size: 14px;
            line-height: 100%;
            text-align: right;
            text-decoration-line: underline;
            color: #2F57E9;
          }
        }
      }
    }
  }

  &__item {
    padding: 20px 10px;
    border-radius: 10px;

    &:nth-child(odd) {
      background: rgba(240, 242, 248, .5);
    }

    &__start {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__name {
        font-size: 16px;
        line-height: 100%;
        color: #202539;
        font-family: Medium;
      }

      &__date {
        font-size: 12px;
        line-height: 100%;
        text-align: right;
        color: #202539;
        font-family: Medium;
      }
    }

    &__center {
      margin: 20px 0 0;
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__name {
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
        }

        &__content {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          line-height: 100%;
          color: #202539;
          font-family: Medium;

          span {
            color: #9CA3B8;
            font-size: inherit;
            line-height: inherit;
          }
        }
      }
    }
  }

  &__navigation {
    margin-top: 20px;
    padding: 0 10px;

    &__content {
      padding: 20px 0 0;
      border-top: 1px solid #9CA3B8;
    }
  }
}
</style>
